import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Carousel from "../component/Carousel";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import Select from "../component/Select";
import BookModal from "../component/BookModal";
import CONSTANT from "../constant.json";
import { MdExpandMore } from "react-icons/md";
import axios from "axios";
import DoctorCardSkeleton from "../component/CardSkeleton";
import { Rate, Tooltip } from "antd";
import FilterModal from "../component/FilterModal";
import { IoIosArrowDown } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import { FaXmark } from "react-icons/fa6";
function FindDoctor() {
  const [doctorData, setDoctorData] = useState([]);
  const [doctorApiData, setDoctorApiData] = useState([]);
  const [docCategories, setdocCategories] = useState([]);
  const [catModalList, setCatModalList] = useState([]);
  const [symptomsList, setSymptomsList] = useState([]);
  const [searchData, setSearchData] = useState({
    name: "",
    symptom: "",
    slot: "",
  });
  // const docCategories = ; // Add your categories here
  const [activeCategory, setActiveCategory] = useState("All"); // Initialize active category state
  const [loading, setLoading] = useState(true);

  const fetchData = async (query) => {
    try {
      const response = await axios.get(
        `${CONSTANT.url}api/Doctor/get${query ? `?${query}` : ""}`
      );
      setDoctorApiData(response.data?.data);

      console.log(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchCategoriesData = async () => {
    try {
      const response = await axios.get(`${CONSTANT.url}api/categories/get`);
      setdocCategories(response.data?.data.slice(0, 4));
      setCatModalList(response.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchSymptomsData = async () => {
    try {
      const response = await axios.get(`${CONSTANT.url}api/Symptoms/get`);
      console.log(response.data?.data);
      setSymptomsList(response.data?.data);
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
    fetchData();
    fetchSymptomsData();

    // Cleanup function if needed
    return () => {
      // Cleanup code here
    };
  }, []);

  useEffect(() => {
    setDoctorData(doctorApiData);
  }, [doctorApiData]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    // Update active category when a tab is clicked
    if (category === "All") {
      setDoctorData(doctorApiData);
    } else {
      const filteredData = doctorApiData.filter((v) => {
        return v.categories.map((d) => d.name).includes(category);
      });

      setDoctorData(filteredData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSymptomChange = (value) => {
    setSearchData((prevState) => ({
      ...prevState,
      symptom: value,
    }));
  };
  const handleClear = () => {
    setSearchData({
      name: "",
      symptom: "",
      slot: "",
    });
    fetchData();
    setActiveCategory("All");
  };
  const handleSearch = () => {
    const urlSearchParams = new URLSearchParams(searchData);
    console.log(urlSearchParams.toString());
    fetchData(urlSearchParams.toString());
  };

  const handleCardClick = (doctor) => {
    const formattedName = doctor?.name.replace(/\s+/g, "-");
    const doctorCategory =
      doctor?.categories && doctor.categories.length > 0
        ? doctor?.categories[0]?.name.replace(/\s+/g, "-").replace(/&/g, "")
        : "";
    // const newUrl = `/booked-appointment-detail/?id=${doctor.id}?name=${formattedName}`;
    const newUrl = `/booked-appointment-detail/?${
      formattedName + "-" + doctorCategory + "-" + doctor.id
    }`;
    window.location.href = newUrl;
  };

  return (
    <div className="find-doc-page">
      <div
        id="react-app"
        style={{
          maxWidth: "1400px",
          position: "relative",
          margin: "20px auto",
        }}
      >
        <div className="search-doc">
          <div className="search-form-group">
            <input
              onChange={handleChange}
              value={searchData.name}
              name="name"
              className=""
              type="text"
              placeholder="Name"
            />
          </div>
          {/* <div className="search-form-group" ><input onChange={handleChange} value={searchData.departments} name="departments" className="" type="text" placeholder="Departments" /></div> */}
          <div className="search-form-group">
            <Select
              placeholder={"Select Symptom"}
              className={"eclinic-select"}
              options={symptomsList}
              label_key={"name"}
              value_key={"id"}
              seletedValue={searchData.symptom || undefined}
              onChange={handleSymptomChange}
            />
          </div>
          <div className="search-form-group">
            <input
              onChange={handleChange}
              value={searchData.slot}
              name="slot"
              className=""
              type="date"
            />
          </div>
          <div className="search-form-btn">
            <button
              className="appointment-submit-btn"
              type="button"
              onClick={handleClear}
            >
              <FaXmark />
            </button>
            <button
              className="appointment-submit-btn"
              type="button"
              onClick={handleSearch}
            >
              <BiSearch />
            </button>
          </div>
        </div>
        <div className="filter-wrapper">
          <div
            className={`filter-tab ${
              "All" === activeCategory ? "active-filter-tab" : ""
            }`}
            onClick={() => handleCategoryClick("All")}
          >
            All
          </div>
          {docCategories.map((category, index) => (
            <div
              key={index}
              className={`filter-tab ${
                category?.name === activeCategory ? "active-filter-tab" : ""
              }`}
              onClick={() => handleCategoryClick(category?.name)}
            >
              {category?.name}
            </div>
          ))}
          {catModalList.length > 4 ? (
            <FilterModal
              catModalList={catModalList}
              handleCategoryClick={handleCategoryClick}
              activeCategory={activeCategory}
            >
              <div
                className=""
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                View More <IoIosArrowDown />
              </div>
            </FilterModal>
          ) : (
            <></>
          )}
        </div>
        <div className="find-doc-wrapper">
          {doctorData.length ? (
            doctorData.map((doctor, index) => (
              <div
                key={index}
                className="doc-card"
                onClick={() => handleCardClick(doctor)}
              >
                <div className="doc-img-box">
                  {/* <div className="doc-tag">
                    <FaStar /> {doctor.specialist}
                  </div> */}
                  <img
                    src={CONSTANT.img_url + doctor.image}
                    className="doc-img"
                  />
                </div>
                <div className="doc-name">
                  <h2>{doctor.name}</h2>
                  <div style={{ paddingTop: "10px" }}>
                    <span className="grey-text">
                      {doctor.categories[0]?.name}
                    </span>
                    {/* <p className="rate-div">
                      <Rate
                        value={doctor.average_rating || "0"}
                        disabled
                        allowHalf
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      {doctor.average_rating
                        ? doctor.average_rating.slice(0, 3)
                        : "0"}
                    </p> */}
                  </div>
                </div>
                <div className="doc-detail">
                  <div>
                    <p className="grey-text">Experience</p>
                    <p className="detail">{doctor.experience} Years</p>
                  </div>
                  {/* <div>
                    <p className="grey-text">Patients</p>
                    <p className="detail">{doctor.patient_count || "0"}</p>
                  </div> */}
                </div>
                <div className="doc-book-btn">
                  <button type="button" onClick={() => handleCardClick(doctor)}>
                    <span>Check Availability</span>
                    <FaLongArrowAltRight />
                  </button>
                </div>
              </div>
            ))
          ) : loading ? (
            <>
              <DoctorCardSkeleton />
              <DoctorCardSkeleton />
              <DoctorCardSkeleton />
              <DoctorCardSkeleton />
              <DoctorCardSkeleton />
              <DoctorCardSkeleton />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                height: "300px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              There are no doctors in this category.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FindDoctor;
