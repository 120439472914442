// App.js
import React, { useState, useEffect } from "react";
import SignupLogin from "../component/BookAppointent/SignupLogin";
import CONSTANT from "../constant.json";
import axios from "axios";
import { Spin, notification } from "antd";

// _______________status____________

//    1= new user
//    2= token expire
//    3= already login
//
// _______________status____________
const Appointment = () => {
  const [currentStatus, setCurrentStatus] = useState(
    localStorage.getItem("token") ? "3" : "1"
  );
  const [isLoading, setIsLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const book_data = localStorage.getItem("book_data");
  const parsedBookData = JSON.parse(book_data);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };
  const token = localStorage.getItem("token");

  console.log(parsedBookData);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setCurrentStatus("3");
      //  ___ get user data by fetching via from token
      // if token perfect then set status 3 else
      // token expire set status 2
    } else {
      setCurrentStatus("1");
    }
  }, []);

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const bookAppointment = () => {
    setIsLoading(true);
    axios
      .post(
        `${CONSTANT.url}api/payment/paymentgateway`,
        {
          payamount: parsedBookData.data?.fee,
          doctor_id: parsedBookData.data.id,
          date: parsedBookData.date,
          slot: parsedBookData.selectedTimeSlot,
          patient_id: user_id,
        },
        { headers }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data?.data) {
          console.log(response);

          // Dynamically create a form
          const form = document.createElement("form");
          form.setAttribute("method", "post");
          form.setAttribute(
            "action",
            "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction"
          );

          // Add form fields based on response data
          Object.entries(response.data.data).forEach(([key, value]) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", key);
            input.setAttribute("value", value);
            form.appendChild(input);
          });

          // Append the form to the document body and submit it
          document.body.appendChild(form);
          form.submit();
        }
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response?.data)
          if (error.response.data?.data) {
            console.log(Object.values(error.response.data?.data));
            Object.values(error.response.data?.data).map((v) =>
              openNotificationWithIcon(
                "error",
                "Error booking appointment",
                v[0]
              )
            );
          }
      });
  };

  return (
    <div className="appointments-descriptions">
      {contextHolder}
      {currentStatus === "1" || currentStatus === "2" ? (
        <SignupLogin currentStatus={currentStatus} />
      ) : parsedBookData ? (
        <div className="appointment-container" style={{ maxWidth: "650px" }}>
          <Spin spinning={isLoading} fullscreen />

          <div class="logo"></div>
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "38px",
              textAlign: "center",
              fontWeight: 800,
            }}
          >
            Appointment Details
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <img
              src={`${CONSTANT.img_url}${parsedBookData?.data.image_banner}`}
              alt={parsedBookData?.data.name}
              style={{
                width: "90%",
                margin: "10px auto",
                borderRadius: "10px",
              }}
            />
            <div
              style={{
                padding: "5%",
              }}
            >
              <p>
                <b>Name:</b> {parsedBookData?.data.name}
              </p>
              {/* <p>
                <b>Email:</b> {parsedBookData?.data.email}
              </p> */}
              <p>
                <b>Department:</b>{" "}
                {parsedBookData?.data.categories.map((v) => v.name).join(", ")}
              </p>

              <p>
                <b>Experience:</b> {parsedBookData?.data.experience} years
              </p>
              {/* <p>
                <b>Address:</b> {parsedBookData?.data.address}
              </p> */}
              <p>
                <b>About:</b>
                <div
                  dangerouslySetInnerHTML={{
                    __html: parsedBookData?.data.about,
                  }}
                />
                {/* {parsedBookData?.data.about} */}
              </p>
              <p>
                <b>Date:</b> {new Date(parsedBookData?.date).toDateString()}
              </p>
              <p>
                <b>Time Slot:</b> {parsedBookData?.labelSlotTime}
              </p>
              <div
                style={{
                  display: "flex",
                  gap: 25,
                  marginTop: 25,
                  flexWrap: "wrap",
                }}
              >
                <button
                  className="appointment-submit-btn"
                  onClick={bookAppointment}
                  style={{ marginTop: "10px" }}
                >
                  Book Now
                </button>
                <button
                  className="appointment-submit-btn"
                  onClick={() =>
                    (window.location.href =
                      "https://eclinicasia.com/find-your-doctor/")
                  }
                  style={{ marginTop: "10px" }}
                >
                  Get a New Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{ maxWidth: "300px", marginTop: "30px" }}
            className="appointment-submit-btn"
            onClick={() =>
              (window.location.href =
                "https://eclinicasia.com/find-your-doctor/")
            }
          >
            Get a New Appointment
          </button>
        </div>
      )}
    </div>
  );
};

export default Appointment;
