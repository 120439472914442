import React, { useState } from 'react';
import { Button, Modal } from 'antd';
const FilterModal = ({ children, catModalList, handleCategoryClick, activeCategory }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("")
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setSearchInput('')
    };
    return (
        <>
            <div className='filter-view-more' onClick={showModal}>{children}</div>
            <Modal footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='filter-modal-main'>

                    <div className='filter-modal-search'>
                        <input type='text' className='' value={searchInput} placeholder='Search....' onChange={(e) => setSearchInput(e.target.value)} />
                    </div>

                    <div className='filter-modal-tab-warpper'>
                        {Array.isArray(catModalList) && catModalList.length ? <>
                            {searchInput.trim() === "" ? (
                                // If search input is empty, display all categories
                                catModalList.map((category, index) => (
                                    <div
                                        key={index}
                                        className={`filter-tab ${category?.name === activeCategory ? "active-filter-tab" : ""
                                            }`}
                                        onClick={() => { handleCategoryClick(category?.name); handleCancel(); }}
                                    >
                                        {category?.name}
                                    </div>
                                ))
                            ) : (
                                // If search input is not empty, filter categories based on search input
                                catModalList.filter(v => v.name.toLocaleLowerCase()?.includes(searchInput.toLocaleLowerCase())).map((category, index) => (
                                    <div
                                        key={index}
                                        className={`filter-tab ${category?.name === activeCategory ? "active-filter-tab" : ""
                                            }`}
                                        onClick={() => { handleCategoryClick(category?.name); handleCancel(); }}
                                    >
                                        {category?.name}
                                    </div>
                                ))
                            )}</> : <div></div>}
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default FilterModal;