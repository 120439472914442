import React from 'react';
import { Select } from 'antd';


// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const SelectCustom = ({ options, label_key, value_key, seletedValue, onChange, disabled, className, placeholder }) => {

    const optionArray = Array.isArray(options) ? options.map(v => ({ value: v[value_key], label: v[label_key] })) : []

    const onSearch = (value) => {
    };

    return (
        <Select
            disabled={disabled}
            showSearch
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={optionArray}
            className={`book-ant-select ${className}`}
            value={seletedValue}
            style={{ width: '100%', minWidth: '200px' }}
        />
    )
};
export default SelectCustom;