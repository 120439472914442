import React, { useRef } from 'react';

function OtpForm({ onSubmit, otp, setOtp }) {
  let numberOfDigits = 6;
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }
  function handlePaste(e) {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const otpArray = pastedData.split('').slice(0, numberOfDigits);
    setOtp(otpArray);

    otpArray.forEach((value, index) => {
      if (index < numberOfDigits - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    });
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", alignItems: "center", marginTop: 50, justifyContent: "center" }}>
        {otp.map((digit, index) => (
          <input
            key={index}
            value={digit}
            maxLength={1}
            className='otp-input'
            placeholder='_'
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            onPaste={(e) => handlePaste(e)}
          />
        ))}
      </div>
      <button
        type="submit"
        onClick={onSubmit}
        className="appointment-submit-btn"
        style={{ width: "90%", marginBottom: "30px", marginTop: "20px" }}
      >
        Submit OTP
      </button>
    </div>
  );
}

export default OtpForm;
