import React from "react";
import { Skeleton } from "antd";

const ImagedoctorSkeletonss = () => {
  return (
    <div
      className="doc-card-skeleton"
      style={{ width: "70%", height: 300, margin: "auto", marginTop: "20px" }}
    >
      <Skeleton.Image style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default ImagedoctorSkeletonss;
