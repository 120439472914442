import React, { useState } from "react";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidLeftArrow } from "react-icons/bi";
import BookModal from "./BookModal";
import CONSTANT from "../constant.json";
import { Rate } from "antd";
import { useNavigate } from "react-router-dom";

const drImg =
  "https://t4.ftcdn.net/jpg/03/20/52/31/360_F_320523164_tx7Rdd7I2XDTvvKfz2oRuRpKOPE5z0ni.jpg";

function Carousel({ data }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const navigate = useNavigate();

  const NextArrow = (props) => (
    <div
      {...props}
      style={{ display: "flex" }}
      className="next-arrow doc-next-arr"
    >
      <BiSolidRightArrow />
    </div>
  );
  const PrevArrow = (props) => (
    <div
      {...props}
      style={{ display: "flex" }}
      className="next-arrow doc-prev-arr"
    >
      <BiSolidLeftArrow />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.length < 4 ? data.length : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  const handleOpenModal = (doctor) => {
    setSelectedDoctor(doctor);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setModalVisible(false);
  };

  let arr =
    Array.isArray(data) && data.length === 1 ? [...data, ...data] : data;

  const handleCardClick = (doctor) => {
    const formattedName = doctor?.name.replace(/\s+/g, "-");
    const doctorCategory =
      doctor?.categories && doctor.categories.length > 0
        ? doctor?.categories[0]?.name.replace(/\s+/g, "-").replace(/&/g, "")
        : "";
    // const newUrl = `/booked-appointment-detail/?id=${doctor.id}?name=${formattedName}`;
    const newUrl = `/booked-appointment-detail/?${
      formattedName + "-" + doctorCategory + "-" + doctor.id
    }`;
    window.location.href = newUrl;
  };

  return (
    <div
      id="react-app"
      style={{ maxWidth: "1100px", position: "relative", margin: "0 auto" }}
    >
      <div className="slider-container">
        <Slider {...settings}>
          {Array.isArray(arr) &&
            arr.map((doctor, index) => (
              <div
                key={index}
                className="doc-card"
                onClick={() => handleCardClick(doctor)}
              >
                <div className="doc-img-box">
                  {/* <div className="doc-tag">
                    <FaStar /> {doctor.specialist}
                  </div> */}
                  <img
                    src={CONSTANT.img_url + doctor.image}
                    className="doc-img"
                  />
                </div>
                <div className="doc-name">
                  <h2>{doctor.name}</h2>
                  <div style={{ paddingTop: "10px" }}>
                    <span className="grey-text">
                      {doctor.categories[0]?.name}
                    </span>
                    {/* <p className="rate-div">
                      <Rate
                        value={doctor.average_rating || "0"}
                        disabled
                        allowHalf
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      {doctor.average_rating
                        ? doctor.average_rating.slice(0, 3)
                        : "0"}
                    </p> */}
                  </div>
                </div>
                <div className="doc-detail" style={{ padding: "0 23px" }}>
                  <div>
                    <p className="grey-text">Experience</p>
                    <p className="detail">{doctor.experience} Years</p>
                  </div>
                  {/* <div>
                    <p className="grey-text">Patients</p>
                    <p className="detail">{doctor.patient_count || "0"}</p>
                  </div> */}
                </div>
                <div className="doc-book-btn">
                  <button type="button" onClick={() => handleCardClick(doctor)}>
                    <span>Check Availability</span>
                    <FaLongArrowAltRight />
                  </button>
                </div>
              </div>
            ))}
        </Slider>
      </div>
      {/* {selectedDoctor && (
        <BookModal
          isOpen={modalVisible}
          handleOpen={() => setModalVisible(true)}
          handleClose={handleCloseModal}
          data={selectedDoctor}
        />
      )} */}
    </div>
  );
}

export default Carousel;
