// src/pages/BookedAppointmentPage.js
import React, { useEffect, useState } from "react";
import { Rate } from "antd";
import { FaStar, FaLongArrowAltRight } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Select from "../component/Select";
import ImagedoctorSkeletonss from "../component/imageskeleton";
import CONSTANT from "../constant.json";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Appointment = () => {
  const Location = useLocation();

  const [date, setDate] = useState(new Date());
  const [availableSlot, setAvailableSlot] = useState([]);
  const [langPref, setLangPref] = useState([{ name: "English" }]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedLangPref, setSelectedLangPref] = useState("English");
  const [allAvailableDays, setAllAvailableDays] = useState([]);
  const [labelSlotTime, setLabelSlotTime] = useState("");
  const [comments, setComments] = useState("");
  const [doctorApiData, setDoctorApiData] = useState([]);
  const urlPssarams = new URLSearchParams(Location.search);
  const nameWithId = urlPssarams.keys().next().value;
  const idddd = nameWithId?.split("-").pop();

  console.log("spciailidd", idddd);

  const fetchData = async () => {
    const doctorId = parseInt(idddd, 10); // Convert to number

    try {
      const response = await axios.get(
        `${CONSTANT.url}api/Doctor/get?id=${doctorId}`
      );
      setDoctorApiData(response.data?.data?.[0]);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (doctorApiData || idddd) {
      const doctorId = parseInt(idddd, 10);

      const formattedDate = handleDateFormatter(date);
      fetchAvailableSlots(doctorId, formattedDate);
      fetchAvailableDays(doctorId);
    }

    console.log("allllllldoctorssssssssssss", doctorApiData);
  }, [date, doctorApiData]);

  const handleDateFormatter = (dateToFormat) => {
    const currentDate = new Date(dateToFormat);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formatTime = (timeString) => {
    const dateformatTime = new Date(`2000-01-01T${timeString}`);
    return dateformatTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const fetchAvailableSlots = async (doc_id, date) => {
    try {
      const response = await axios.get(
        `${CONSTANT.url}api/appointments/check/${doc_id}/${date}`
      );

      if (Array.isArray(response.data?.data)) {
        if (response.data?.data.length) {
          const slotTimeFormat = response.data?.data.map((v) => ({
            ...v,
            timing: `${formatTime(v.start_time)} - ${formatTime(v.end_time)}`,
          }));
          setAvailableSlot(slotTimeFormat);
        } else {
          setAvailableSlot([]);
        }
      } else {
        setAvailableSlot([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAvailableDays = async (doc_id) => {
    try {
      const response = await axios.get(
        `${CONSTANT.url}api/appointments/days/${doc_id}`
      );

      if (Array.isArray(response.data?.data)) {
        setAllAvailableDays(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const daysIndex = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const tileDisabled = ({ date }) => {
    return !allAvailableDays.includes(date.getDay());
  };

  const handleDateChange = (value) => {
    setSelectedTimeSlot("");
    setDate(value);
  };

  const handleTimeSlotChange = (value, data) => {
    setSelectedTimeSlot(value);
    setLabelSlotTime(data?.label);
  };

  const handleLangPrefChange = (value) => {
    setSelectedLangPref(value);
  };

  const handleBookAppointment = () => {
    let data = doctorApiData;
    const submitData = {
      labelSlotTime,
      selectedTimeSlot,
      data,
      date: handleDateFormatter(date),
      patient_comment: comments,
    };

    if (selectedTimeSlot) {
      localStorage.setItem("book_data", JSON.stringify(submitData));
      window.location.href = "https://eclinicasia.com/book-appointments/";
    } else {
      alert("Select a Time Slot");
    }
  };

  return (
    <div style={{ padding: "100px 10px", fontFamily: "poppins" }}>
      <div style={{ width: "80%", marginTop: "30px", margin: "auto" }}>
        <div style={{ margin: "auto" }}>
          {doctorApiData?.image_banner ? (
            <img
              src={CONSTANT.img_url + doctorApiData?.image_banner}
              className="book-isssssmg"
              alt="Doctor"
            />
          ) : (
            <div style={{ height: "100%", marginTop: "40px" }}>
              <ImagedoctorSkeletonss />
            </div>
          )}
        </div>
        <div className="book-detail" style={{ marginTop: "20px" }}>
          <div className="book-name">
            <h1 style={{ fontFamily: "poppins" }}>{doctorApiData?.name}</h1>
            <p>
              <span className="" style={{ fontFamily: "poppins" }}>
                {doctorApiData?.categories
                  ?.map((category) => category.name)
                  .join(", ") || "No categories available"}
              </span>
            </p>
          </div>
          <div>
            <div
              className="book-exp"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <p style={{ fontFamily: "poppins" }}>Experience </p>
              <p style={{ fontweight: "400", fontFamily: "poppins" }}>
                {doctorApiData?.experience} Years
              </p>
            </div>
            {/* <div
              className="rate-div"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Rate
                value={doctorApiData?.average_rating || "0"}
                disabled
                allowHalf
                style={{ fontSize: "16px", marginRight: "5px" }}
              />
              {doctorApiData?.average_rating
                ? doctorApiData?.average_rating.slice(0, 3)
                : "0"}
            </div> */}
          </div>
        </div>
        <div className="book-about" style={{ display: "flex" }}>
          <div
            className="description"
            style={{
              width: "100%",
              textAlign: "justify",
              lineHeight: "",
              padding: "10px 0px",
            }}
          >
            <h2 style={{ marginBottom: "5px", fontFamily: "poppins" }}>
              Qualification{" "}
            </h2>
            <span style={{ marginTop: "0px", fontFamily: "poppins" }}>
              {doctorApiData?.qualification?.map((v) => v?.name).join(", ")}
            </span>
          </div>
        </div>
        <div className="book-about" style={{ display: "flex" }}>
          <div
            className="description"
            style={{
              width: "100%",
              textAlign: "justify",
              lineHeight: "20px",
              padding: "10px 0px",
            }}
          >
            <h2 style={{ marginBottom: "0px", fontFamily: "poppins" }}>
              About
            </h2>
            <div
              style={{ marginTop: "5px", fontFamily: "poppins" }}
              dangerouslySetInnerHTML={{ __html: doctorApiData?.about }}
            />
          </div>
        </div>
        <div className="book-available" style={{ marginBottom: "30px" }}>
          <h2 style={{ fontFamily: "poppins" }}>Select Your Day</h2>
          <div className="book-form">
            <div className="book-calendar" style={{ maxWidth: "300px" }}>
              <Calendar
                value={date}
                minDate={new Date()}
                onChange={handleDateChange}
                tileDisabled={tileDisabled}
              />
            </div>
            <div
              className="form-input-container"
              style={{ marginTop: "-20px" }}
            >
              <div style={{ width: "100%" }}>
                <p className="form-input">Fee : {doctorApiData?.fee} PKR</p>
              </div>
              {/* <div style={{ width: "100%" }}>
                <Select
                  placeholder={"Communication Preference"}
                  className={"eclinic-select"}
                  options={langPref}
                  label_key={"name"}
                  value_key={"name"}
                  seletedValue={selectedLangPref}
                  onChange={handleLangPrefChange}
                />
              </div> */}
              <div style={{ width: "100%", marginTop: "10px" }}>
                {Array.isArray(availableSlot) && availableSlot.length ? (
                  <Select
                    placeholder={"Select Available Time Slots"}
                    className={"eclinic-select"}
                    options={availableSlot}
                    label_key={"timing"}
                    value_key={"id"}
                    seletedValue={selectedTimeSlot || undefined}
                    onChange={handleTimeSlotChange}
                  />
                ) : (
                  <div className="form-input">No Slots Available</div>
                )}
              </div>
              <div
                style={{ width: "100%", display: "flex", marginBottom: "0px" }}
              >
                <textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  className="form-input"
                  placeholder="Comments"
                  style={{ flex: 1 }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <div className="book-appoinment-btn">
                  <button
                    type="button"
                    style={{ height: "50px", marginTop: "auto" }}
                    onClick={handleBookAppointment}
                  >
                    <span>Book Appointment</span>
                    <FaLongArrowAltRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
