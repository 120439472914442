import { Skeleton } from 'antd';
import { FaStar, FaLongArrowAltRight } from 'react-icons/fa';

const DoctorCardSkeleton = () => {
    return (
        <div className="doc-card">
            <Skeleton.Input style={{ width: '230px', height: '230px', borderRadius: '15px' }} active />

            <Skeleton.Input style={{ width: '230px', marginTop: '10px', height: '50px' }} active />
            <Skeleton.Input style={{ width: '180px', marginTop: '5px', height: '20px' }} active />
            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <Skeleton.Button style={{ maxWidth: '50px', marginTop: '5px', height: '50px' }} active />
                <Skeleton.Button style={{ maxWidth: '50px', marginTop: '5px', height: '50px' }} active />
                <Skeleton.Button style={{ maxWidth: '50px', marginTop: '5px', height: '50px' }} active />

            </div>
            <div className="doc-book-btn">
                <Skeleton.Button style={{ width: '230px' }} active />
            </div>
        </div>
    );
}

export default DoctorCardSkeleton;
